import { API, requests } from '@/api/apis'
import { useToken } from '../../common/hooks/useToken'
import router from '@/router'

export interface AuthStateType {
  user: object
  permissions: object
  worlflowlist: any
  wonumber: number
}
const { setToken } = useToken('ADMIN_TOKEN')
const state = {
  user: {},
  permissions: {},
  worlflowlist: [],
  wonumber: 0,
}

const getters = {
  user: (state: AuthStateType) => state.user,
  permissions: (state: AuthStateType) => state.permissions,
  worlflowlist: (state: AuthStateType) => state.worlflowlist,
  wonumber: (state: AuthStateType) => state.wonumber,
}

const actions = {
  loginAction({ commit }: any, params: unknown) {
    requests({ url: API.Auth.Login, data: params }).then((res) => {
      console.log(res, location, '登陆')
      const data = res.data as any
      commit('setLogin', data.user)
      commit('setMenu', data.permissions)
      setToken(data.token.access_token)
      if (location.hash == '#/login') {
        router.replace('/Home')
      }
    })
  },
  meAction({ commit }: any) {
    requests({ url: API.Auth.Me }).then((res: any) => {
      console.log(res, 'me')
      commit('setLogin', res.data.user)
      commit('setMenu', res.data.permissions)
      if (location.hash == '#/login') {
        router.replace('/Home')
      }
    })
  },
  logoutAction({ commit }: any) {
    requests({ url: API.Auth.Logout }).then(() => {
      commit('setLogin', null)
      setToken('')
    })
  },
  myWorlflowFun({ commit }: any, list: any) {
    commit('setWorlflow', list)
  },
  MyWorlflowNumFun({ commit }: any, num: any) {
    commit('setWorlflowNum', num)
  },
}

const mutations = {
  setLogin(state: AuthStateType, user: object) {
    state.user = user
  },
  setMenu(state: AuthStateType, menu: object) {
    state.permissions = menu
  },
  setWorlflow(state: AuthStateType, list: any) {
    state.worlflowlist = list
  },
  setWorlflowNum(state: AuthStateType, num: number) {
    state.wonumber = num
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
