import axios from '@/common/utils/axios'
import { AxiosRequestConfig } from 'axios'

export const requests = (options: AxiosRequestConfig) => {
  return axios({ method: 'post', ...options } as AxiosRequestConfig)
}

export const API = {
  Auth: {
    Login: 'auth/login',
    Me: 'auth/me',
    Logout: 'auth/logout',
    AliyunSts: 'auth/aliyun_sts',
    UpdateMe: 'auth/update_me',
  },
  Projects: {
    List: 'projects/list',
    Store: 'projects/store',
    Show: 'projects/show',
    Update: 'projects/update',
    Destroy: 'projects/delete',
    Restore: 'projects/restore',
    Select: 'projects/select',
    ForceDelete: 'projects/force_delete',
    ToggleEnable: 'projects/toggle_enable',
  },
  ProjectPlans: {
    All_list: 'project_plans/all_list',
    Audit_list: 'project_plans/audit_list',
    Audited_list: 'project_plans/audited_list',
    My_list: 'project_plans/my_list',
    Store: 'project_plans/store',
    Show: 'project_plans/show',
    Update: 'project_plans/update',
    Destroy: 'project_plans/delete',
    ForceDelete: 'project_plans/force_delete',
    Audit: 'project_plans/audit',
  },
  effectpictureapply: {
    List: 'project_apply/list',
    Store: 'project_apply/store',
    Show: 'project_apply/show',
    Update: 'project_apply/update',
    Destroy: 'project_apply/destroy',
  },
  acceptance: {
    List: 'project_picture/list',
    Store: 'project_picture/store',
    Show: 'project_picture/show',
    Update: 'project_picture/update',
    Destroy: 'project_picture/destroy',
  },

  departments: {
    List: 'departments/list',
    Store: 'departments/store',
    Show: 'departments/show',
    Update: 'departments/update',
    Destroy: 'departments/delete',
    Select: 'departments/select',
  },
  admins: {
    List: 'admins/list',
    Store: 'admins/store',
    Show: 'admins/show',
    Update: 'admins/update',
    Destroy: 'admins/delete',
    Restore: 'admins/restore',
    ForceDelete: 'admins/force_delete',
    Select: 'admins/select',
    ToggleEnable: 'admins/toggle_enable',
  },
  roles: {
    List: 'roles/list',
    Store: 'roles/store',
    Show: 'roles/show',
    Update: 'roles/update',
    Destroy: 'roles/delete',
    Select: 'roles/select',
    GetPermissions: 'roles/get_permissions',
    SetPermissions: 'roles/set_permissions',
    SelectForWorkflow: 'roles/select_for_workflow',
  },
  suppliers: {
    List: 'suppliers/list',
    Store: 'suppliers/store',
    Show: 'suppliers/show',
    Update: 'suppliers/update',
    Destroy: 'suppliers/delete',
    Restore: 'suppliers/restore',
    ForceDelete: 'suppliers/force_delete',
    Select: 'suppliers/select',
    UpdatePrice: 'suppliers/update_price',
  },
  permissions: {
    List: 'permissions/list',
    Store: 'permissions/store',
    Show: 'permissions/show',
    Update: 'permissions/update',
    Destroy: 'permissions/delete',
    Restore: 'permissions/restore',
    ForceDelete: 'permissions/force_delete',
    Move: 'permissions/move',
  },
  ProjectApplies: {
    BeforeStore: 'project_applies/before_store',
    Store: 'project_applies/store',
    Update: 'project_applies/update',
    MyList: 'project_applies/my_list',
    AuditList: 'project_applies/audit_list',
    AllList: 'project_applies/all_list',
    AuditedList: 'project_applies/audited_list',
    Audit: 'project_applies/audit',
    Show: 'project_applies/show',
    Steps: 'project_applies/steps',
    Delete: 'project_applies/delete',

    select_flow_step: 'project_applies/select_flow_step',
    repaid_flow_audit: 'project_applies/repaid_flow_audit',
    supplier_audit_list: 'project_applies/supplier_audit_list',
  },
  project_accepts: {
    AllList: 'project_accepts/all_list',
    AuditList: 'project_accepts/audit_list',
    AuditedList: 'project_accepts/audited_list',
    Show: 'project_accepts/show',
    Audit: 'project_accepts/audit',
    QueryForSelect: 'project_accepts/query_for_select',
    Delete: 'project_accepts/delete',
    select_flow_step: 'project_accepts/select_flow_step',
    repaid_flow_audit: 'project_accepts/repaid_flow_audit',
  },
  project_trips: {
    AllList: 'project_trips/all_list',
    AuditList: 'project_trips/audit_list',
    AuditedList: 'project_trips/audited_list',
    MyList: 'project_trips/my_list',
    Store: 'project_trips/store',
    Show: 'project_trips/show',
    Audit: 'project_trips/audit',
  },
  project_department_ratios: {
    Store: 'project_department_ratios/store',
    Update: 'project_department_ratios/update',
    Destroy: 'project_department_ratios/delete',
  },
  project_graph_applies: {
    Alllist: 'project_graph_applies/all_list',
    Store: 'project_graph_applies/store',
    Mylist: 'project_graph_applies/my_list',
    Show: 'project_graph_applies/show',
    Auditedlist: 'project_graph_applies/audited_list',
    Auditlist: 'project_graph_applies/audit_list',
    Audit: 'project_graph_applies/audit',
    Update: 'project_graph_applies/update',
  },
  project_graph_accepts: {
    Alllist: 'project_graph_accepts/all_list',
    Store: 'project_graph_accepts/store',
    Mylist: 'project_graph_accepts/my_list',
    Show: 'project_graph_accepts/show',
    Auditedlist: 'project_graph_accepts/audited_list',
    Auditlist: 'project_graph_accepts/audit_list',
    Audit: 'project_graph_accepts/audit',
  },
}
