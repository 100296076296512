import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/common/layouts/layout.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Layout',
    redirect: '/Home',
    component: Layout,
    children: [
      {
        path: 'Home',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
      },
    ],
  },
  {
    path: '/admin/project/',
    name: 'AdminProject',
    redirect: '/admin/project',
    component: Layout,
    children: [
      {
        path: 'list',
        name: 'project',
        component: () => import('../views/project/list.vue'),
      },
      {
        path: 'pages/show',
        name: 'project_show',
        component: () => import('../views/project/pages/show.vue'),
      },
    ],
  },
  {
    path: '/admin/project_plans/',
    name: 'project_plans',
    redirect: '/admin/project_plans/all_list',
    component: Layout,
    children: [
      {
        path: 'all_list',
        name: 'project_plans_all_list',
        component: () => import('../views/project_plans/all_list.vue'),
      },
      {
        path: 'my_list',
        name: 'project_plans_my_list',
        component: () => import('../views/project_plans/my_list.vue'),
      },
      {
        path: 'audit_list',
        name: 'project_plans_audit_list',
        component: () => import('../views/project_plans/audit_list.vue'),
      },
      {
        path: 'audited_list',
        name: 'project_plans_audited_list',
        component: () => import('../views/project_plans/audited_list.vue'),
      },
      {
        path: 'project_plans_create',
        name: 'project_plans_create',
        component: () => import('../views/project_plans/page/Createplan.vue'),
      },
      {
        path: 'project_plans_update',
        name: 'project_plans_update',
        component: () => import('../views/project_plans/page/Updateplan.vue'),
      },
      {
        path: 'project_plans_view',
        name: 'project_plans_view',
        component: () => import('../views/project_plans/page/ViewPage.vue'),
      },
    ],
  },
  {
    path: '/admin/effectpictureapply',
    name: 'AdminEffectpictureapply',
    redirect: '/admin/effectpictureapply',
    component: Layout,
    children: [
      {
        path: 'all_list',
        name: 'effectpictureapply_all_list',
        component: () => import('../views/effectpictureapply/all_list.vue'),
      },
      {
        path: 'audit_list',
        name: 'effectpictureapply',
        component: () => import('../views/effectpictureapply/audit_list.vue'),
      },
      {
        path: 'audited_list',
        name: 'effectpictureapply_audited_list',
        component: () => import('../views/effectpictureapply/audited_list.vue'),
      },
      {
        path: 'my_list',
        name: 'myeffectpictureapply',
        component: () => import('../views/effectpictureapply/my_list.vue'),
      },
      {
        path: 'initiate',
        name: 'initiate_apply',
        component: () =>
          import('../views/effectpictureapply/page/initiate.vue'),
      },
      {
        path: 'historylist',
        name: 'historylist_apply',
        component: () =>
          import('../views/effectpictureapply/page/historylist.vue'),
      },
      {
        path: 'viewpage',
        name: 'view_page_apply',
        component: () =>
          import('../views/effectpictureapply/page/ViewPage.vue'),
      },
      {
        path: 'original_image',
        name: 'original_image_apply',
        component: () =>
          import('../views/effectpictureapply/page/original_image.vue'),
      },
      {
        path: 'project_applies_update',
        name: 'project_applies_update',
        component: () => import('../views/effectpictureapply/page/Update.vue'),
      },
      {
        path: 'changepicture',
        name: 'project_applies_Change_picture',
        component: () =>
          import('../views/effectpictureapply/page/Change_picture.vue'),
      },
      {
        path: 'supplier_list',
        name: 'effectpictureapply_supplier_list',
        component: () =>
          import('../views/effectpictureapply/supplier_list.vue'),
      },
    ],
  },
  {
    path: '/admin/acceptance/',
    name: 'AdminAcceptance',
    redirect: '/admin/acceptance',
    component: Layout,
    children: [
      {
        path: 'all_list',
        name: 'acceptance_all_list',
        component: () => import('../views/acceptance/all_list.vue'),
      },
      {
        path: 'audit_list',
        name: 'acceptance_audit_list',
        component: () => import('../views/acceptance/audit_list.vue'),
      },
      {
        path: 'audited_list',
        name: 'acceptance_audited_list',
        component: () => import('../views/acceptance/audited_list.vue'),
      },
      {
        path: 'viewpage',
        name: 'acceptance_show',
        component: () => import('../views/acceptance/page/viewPage.vue'),
      },
      {
        path: 'compared',
        name: 'acceptance_compared',
        component: () => import('../views/acceptance/page/comparedPage.vue'),
      },
    ],
  },
  {
    path: '/admin/businesstrip',
    name: 'Adminbusinesstrip',
    redirect: '/admin/businesstrip',
    component: Layout,
    children: [
      {
        path: 'all_list',
        name: 'businesstrip_all_list',
        component: () => import('../views/businesstrip/all_list.vue'),
      },
      {
        path: 'audit_list',
        name: 'businesstrip_audit_list',
        component: () => import('../views/businesstrip/audit_list.vue'),
      },
      {
        path: 'audited_list',
        name: 'businesstrip_audited_list',
        component: () => import('../views/businesstrip/audited_list.vue'),
      },
      {
        path: 'my_list',
        name: 'businesstrip_my_list',
        component: () => import('../views/businesstrip/my_list.vue'),
      },
      {
        path: 'create',
        name: 'businesstrip_create',
        component: () => import('../views/businesstrip/page/Create.vue'),
      },
      {
        path: 'view',
        name: 'businesstrip_view',
        component: () => import('../views/businesstrip/page/ViewPage.vue'),
      },
    ],
  },
  {
    path: '/admin/map/',
    name: 'map_picture',
    redirect: '/admin/map/',
    component: Layout,
    children: [
      {
        path: 'alllist',
        name: 'map_picture_list',
        component: () => import('../views/mappicture/AllList.vue'),
      },
      {
        path: 'mylist',
        name: 'map_picture_mylist',
        component: () => import('../views/mappicture/MyList.vue'),
      },
      {
        path: 'show',
        name: 'map_picture_show',
        component: () => import('../views/mappicture/page/show.vue'),
      },
      {
        path: 'auditlist',
        name: 'map_picture_auditList',
        component: () => import('../views/mappicture/AuditList.vue'),
      },
      {
        path: 'auditedList',
        name: 'map_picture_auditedList',
        component: () => import('../views/mappicture/AuditedList.vue'),
      },
      {
        path: 'create',
        name: 'map_picture_create',
        component: () => import('../views/mappicture/create.vue'),
      },
      {
        path: 'update',
        name: 'map_picture_update',
        component: () => import('../views/mappicture/update.vue'),
      },
    ],
  },
  {
    path: '/admin/acceptancemap/',
    name: 'map_acceptance',
    redirect: '/admin/acceptancemap/',
    component: Layout,
    children: [
      {
        path: 'alllist',
        name: 'map_acceptance_list',
        component: () => import('../views/acceptancemap/all_list.vue'),
      },
      {
        path: 'show',
        name: 'map_acceptance_show',
        component: () => import('../views/acceptancemap/page/viewPage.vue'),
      },
      {
        path: 'auditlist',
        name: 'map_acceptance_auditList',
        component: () => import('../views/acceptancemap/audit_list.vue'),
      },
      {
        path: 'audited_list',
        name: 'map_acceptance_audited_list',
        component: () => import('../views/acceptancemap/audited_list.vue'),
      },
      {
        path: 'create',
        name: 'map_acceptance_create',
        component: () => import('../views/acceptancemap/audited_list.vue'),
      },
    ],
  },
  {
    path: '/admin/',
    name: 'Adminsystem',
    redirect: '/admin/departments/list',
    component: Layout,
    children: [
      {
        path: 'departments/list',
        name: 'departments',
        component: () => import('../views/system/departments/list.vue'),
      },
      {
        path: 'admins/list',
        name: 'admins',
        component: () => import('../views/system/admins/list.vue'),
      },
      {
        path: 'suppliers/list',
        name: 'suppliers',
        component: () => import('../views/system/suppliers/list.vue'),
      },
      {
        path: 'roles/list',
        name: 'roles',
        component: () => import('../views/system/roles/list.vue'),
      },
      {
        path: 'permissions/list',
        name: 'permissions',
        component: () => import('../views/system/permissions/list.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
